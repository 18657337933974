import { Broadcast } from "../../../infrastructure/broadcast/broadcast";
import { IBroadcastBaseEvents } from "@mrs/webclient-shared-ui-lib";

export const DictionaryEvents: Required<IBroadcastBaseEvents> = Broadcast.events(
    "dictionary",
    {
        onCreated: "onDictionaryCreated",
        onUpdated: "onDictionaryUpdated",
        onRemove: "onDictionaryRemove",
        onCreatedItem: "onDictionaryItemCreated",
        onUpdatedItem: "onDictionaryItemUpdated",
        onRemoveItem: "onDictionaryItemRemove",
    },
);
