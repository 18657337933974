import { BaseApiService } from "../../../../sharedKernel/apiService/impl/BaseApiService";
import { IDictionaryApiService } from "../IDictionaryApiService";
import { IRestService } from "../../../../../infrastructure/http/IRestService";
import {
    IDictionaryDto,
    IDictionaryItemDto,
    IDictionaryItemRequestDto,
    ObjectId,
} from "@mrs/webclient-shared-ui-lib";

export class DictionaryApiService extends BaseApiService<IDictionaryDto>
    implements IDictionaryApiService {
    constructor(service: IRestService, resourceName: string) {
        super(service, resourceName);
    }

    async getDictionaryFields(collectionName: string): Promise<IDictionaryDto> {
        return this.restService
            .get(`${this.resourcePath}/collection/${collectionName}`)
            .asPromise();
    }

    async getDictionaryItem(
        collectionName: string,
        primaryKey: object,
    ): Promise<IDictionaryItemDto> {
        return this.restService
            .get(`${this.resourcePath}/item/`, {
                params: {
                    collection_name: collectionName,
                    key: JSON.stringify(primaryKey),
                },
            })
            .asPromise();
    }

    async updateDictionaryItem(data: IDictionaryItemRequestDto) {
        return this.restService
            .put(`${this.resourcePath}/item/`, {
                data,
            })
            .asPromise();
    }

    async createDictionaryItem(data: IDictionaryItemRequestDto): Promise<void> {
        return this.restService
            .post(`${this.resourcePath}/item/add/one`, {
                data,
            })
            .asPromise();
    }

    async deleteDictionaryItem(
        data: IDictionaryItemRequestDto[],
    ): Promise<ObjectId[]> {
        return this.restService
            .delete(`${this.resourcePath}/item/`, {
                data,
            })
            .asPromise();
    }
}
