import { Exclude } from "class-transformer";
import { TypedIdentifiable } from "../../../sharedKernel/model/TypedIdentifiable";
import { ObjectType } from "../../../ObjectType";

@Exclude()
export class Dictionary extends TypedIdentifiable {
    get type(): ObjectType {
        return ObjectType.DICTIONARY;
    }
}
