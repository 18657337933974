import React, { useCallback, useState } from "react";
import { ICustomComponent } from "@ui/components/shared/CustomComponent/interfaces/ICustomComponent";
import { LocalizationProvider, MobileDateTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import get from "lodash-es/get";
import { DEFAULT_NAMESPACE, LOCALE } from "@lib/translator/i18next";
import { useTranslation } from "react-i18next";
import { MuiTextFieldProps } from "@mui/lab/internal/pickers/PureDateInput";
import TextField from "@mui/material/TextField";
import { Nullable } from "@mrs/webclient-shared-ui-lib";

interface IDateComponentProps extends ICustomComponent {}

export const DateComponent = (props: IDateComponentProps) => {
    const {
        id,
        value = null,
        required,
        placeholder,
        disabled,
        label,
        errors,
        helperText,
        name,
        hidden,
        readonly,
    } = props;

    const [date, setDate] = useState<Nullable<number>>(value);

    const { t, i18n } = useTranslation(DEFAULT_NAMESPACE);
    const currentLocale = get(LOCALE, i18n.language || "en");

    const onChange = async (newDate: Nullable<Date>) => {
        const val = newDate ? new Date(newDate).getTime() : null;
        setDate(val);
    };

    const onClose = useCallback(() => {
        props.onChange && props.onChange(id, date);
    }, [id, date]);

    const renderTextField = useCallback(
        (params: MuiTextFieldProps) => {
            const { inputProps, ...other } = params;
            return (
                <TextField
                    {...other}
                    name={name}
                    InputProps={{
                        notched: false,
                        readOnly: readonly,
                    }}
                    inputProps={{
                        ...inputProps,
                        placeholder: placeholder || "",
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant={"outlined"}
                    fullWidth
                    required={required}
                    helperText={helperText}
                    error={!!errors?.length}
                />
            );
        },
        [helperText, errors, required],
    );

    return !hidden ? (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={currentLocale}
        >
            <MobileDateTimePicker
                label={label}
                value={date}
                onChange={onChange}
                onClose={onClose}
                disabled={disabled}
                renderInput={renderTextField}
                okText={t("common.ok")}
                cancelText={t("common.cancel")}
            />
        </LocalizationProvider>
    ) : null;
};
