import React, { useCallback, useMemo } from "react";
import {
    IActionButtonProps,
    TVoidCallback,
    UniversalDialog,
} from "@mrs/webclient-shared-ui-lib";
import { useTranslation } from "react-i18next";
import { DEFAULT_NAMESPACE } from "@lib/translator/i18next";
import { useTheme } from "@mui/material/styles";
import JSONInput from "react-json-editor-ajrm-ts-platform/lib";
import locale from "react-json-editor-ajrm-ts-platform/lib/locale/ru";

interface IJsonDialogProps {
    isOpen: boolean;
    title: string;
    onApply: () => void;
    onClose: () => void;
    onChangeJson: TVoidCallback<object>;
    jsonObject: string;
}

const DIALOG_MAX_WIDTH = 1000;

export const JsonDialog = (props: IJsonDialogProps) => {
    const { isOpen, title, onClose, onApply, jsonObject, onChangeJson } = props;
    const { t } = useTranslation(DEFAULT_NAMESPACE);

    const theme = useTheme();

    const onChange = useCallback((editProps: any) => {
        if (editProps && !editProps.error) {
            onChangeJson(editProps?.jsObject);
        }
    }, []);

    const actions: IActionButtonProps[] = useMemo(() => {
        return [
            {
                title: t("common.cancel"),
                onClick: onClose,
                buttonVariant: "text",
                color: "secondary",
                fullWidth: false,
                size: "medium",
            },
            {
                title: t("common.apply"),
                onClick: onApply,
                buttonVariant: "text",
                color: "primary",
                fullWidth: false,
                size: "medium",
                sx: {
                    color: "primary.main",
                },
            },
        ];
    }, []);

    return (
        <UniversalDialog
            isOpen={isOpen}
            title={title}
            onClose={onClose}
            actions={actions}
            dialogProps={{
                sx: {
                    "& .MuiDialog-paper": {
                        height: "100%",
                        width: "100%",
                        maxWidth: DIALOG_MAX_WIDTH,
                    },
                },
            }}
        >
            <JSONInput
                placeholder={(jsonObject as unknown) as object}
                theme="light_mitsuketa_tribute"
                locale={locale}
                onChange={onChange}
                height={"100%"}
                width={"100%"}
                colors={{ background: theme.palette.background.paper }}
                waitAfterKeyPress={2000}
                style={{
                    body: theme.typography.body2,
                }}
            />
        </UniversalDialog>
    );
};
