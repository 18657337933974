import React from "react";
import { AppMainLayout } from "./components/shared/AppMainLayout/AppMainLayout";
import { CurrentUser } from "../core/context/user/currentUser/CurrentUser";
import { Broadcast } from "../infrastructure/broadcast/broadcast";
import { useState, useEffect } from "react";
import { AuthEvents } from "../core/context/auth/AuthEvents";
import { AuthRoutes } from "./components/shared/AuthRoutes/AuthRoutes";

export const Main: React.FC = () => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
        !!CurrentUser.getToken(),
    );

    useEffect(() => {
        Broadcast.on(AuthEvents.login, () => setIsLoggedIn(true), null);
    }, []);

    return isLoggedIn ? <AppMainLayout /> : <AuthRoutes />;
};
