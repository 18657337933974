import { Identifiable } from "../../../sharedKernel/model/Identifiable";
import { ObjectId } from "@mrs/webclient-shared-ui-lib";
import { dto } from "@mrs/platform-configuration-webclient";

export class ViewHook extends Identifiable {
    private readonly _function: string;
    private readonly _view: ObjectId;

    constructor(dto: dto.hook.ViewHook) {
        super(dto);
        this._function = dto.function;
        this._view = dto.view;
    }

    get function(): string {
        return this._function;
    }

    get hook() {
        return new Function(`return (${this._function})`)();
    }

    get view(): ObjectId {
        return this._view;
    }
}
