import { IResource } from "@lib/translator/Translator";

export const ru: IResource = {
    common: {
        close: "Закрыть",
        cancel: "Отменить",
        delete: "Удалить",
        edit: "Редактировать",
        changed: "Изменено",
        apply: "Применить",
        select: "Выбрать",
        open: "Открыть",
        search: "Поиск",
        filter: "Фильтр",
        create: "Создать",
        send: "Отправить",
        enter: "Ввод",
        sign: "Подписать",
        device: "Устройство",
        pin: "Пин-код",
        certificate: "Сертификат",
        nothingFound: "Ничего не найдено",
        noItems: "Нет элементов",
        generateDocument: "Сформировать документ",
        download: "Скачать",
        additionalInfo: "Дополнительная информация",
        confirmDelete:
            "Вы действительно хотите удалить? Данное действие нельзя отменить.",
        actions: "Действия",
        selectAll: "Выбрать все",
        selectAction: "Выберите действие",
        save: "Сохранить",
        saveChanges: "Сохранить изменения",
        changesSaved: "Изменения сохранены",
        reset: "Сбросить",
        resetAll: "Сбросить все",
        filters: "Фильтры",
        applyFilters: "Применить фильтры",
        moreActions: "Больше",
        generate: "Сформировать",
        owner: "Автор",
        organization: "Организация",
        confirmCloseWithChanges:
            "Вы изменили данные. Вы уверены, что хотите сбросить внесённые изменения?",
        ready: "Готово",
        start: "Начало",
        end: "Окончание",
        tabs: {
            main: "Главная",
            information: "Информация",
        },
        fileSize: {
            mb: "Мб",
        },
        ok: "OK",
        createDate: "Дата создания",
        creation: "Создание",
        linesPerPage: "Строк на странице",
        navigation: {
            hide: "Скрыть навигацию",
            show: "Показать навигацию",
        },
    },
    pages: {
        settings: "Настройки",
    },
    settings: {
        language: {
            title: "Язык",
            ru: "Русский",
            en: "Английский",
        },
        exit: "Выйти",
        logoutConfirm:
            "Войти в систему возможно только в режиме онлайн. \nВыйти из системы? ",
    },
    login: {
        subnet: {
            hint:
                "Это ссылка с вашим корпоративным адресом, которую вы получали на почту.",
            continue: "Продолжить",
            orgAddress: "URL-адрес организации",
            orgEnterTitle: "Войдите в свою организацию",
        },

        notValid: "Введены неверные данные",
        subnetPlaceholder: "Введите URL",
        subnetError: "Неверный url для входа в организацию",

        tryLoginButton: "Попробовать снова",
        loginError: "Ошибка авторизации",
        loginErrorMessages: {
            176: "У вашей организации недостаточно лицензий. Чтобы войти, обратитесь к вашему администратору.",
            500: "Произошла ошибка на сервере. Обратитесь к вашему администратору.",
        },
    },
    reportTemplateDialog: {
        loadingDocument: "Документ отправлен на формирование",
        error: "Ошибка при формировании документа",
        success: "Документ сформирован",
    },
    datePicker: {
        error: {
            maxDateError: "Введена некорректная дата",
            minDateError: "Введена некорректная дата",
            dateFormatError: "Введена некорректная дата",
            dateRangeError: "Неправильный диапазон дат",
        },
    },
    formInstance: {
        create: "Создание экземпляра формы",
        createButton: "Создать",
        enterTitle: "Введите название",
        title: "Название",
        canNotUpdate: "Вы не можете изменять форму",
        updateError: "Не удалось обновить форму",
        createError: "Не удалось создать форму",
    },
    attachment: {
        fileUpload: "Загрузить файл",
        errorMaxAttachmentAmount_0:
            "Вы можете загрузить не более {{count}} файла",
        errorMaxAttachmentAmount_1:
            "Вы можете загрузить не более {{count}} файлов",
        errorMaxAttachmentAmount_2:
            "Вы можете загрузить не более {{count}} файлов",
        errorFileType: "Вы пытаетесь загрузить файл неподдерживаемого типа",
        errorFileSize:
            "Вы пытаетесь загрузить файл размером более {{value}} Мб",
        errorUnknown: "Ошибка при загрузке файла",
        uploadError: "Ошибка загрузки",
        loading: "Загрузка",
        loadingWithSize: "Загрузка {{done}} Мб из {{total}} Мб",
        uploadButtonMessage: "Максимальный размер {{value}} Мб",
        emptyList: "Нет файлов",
    },
    customForm: {
        selectUser: {
            title: "Выбрать пользователя",
            noOptions: "Нет совпадений",
            userNamePlaceholder: "Введите имя пользователя",
            assignedTo: "Проверяющий",
            count: "{{ count }}",
        },
        selectStatus: {
            placeholder: "Выберите статус",
        },
        selectObject: "Выбрать объект на BIM-модели",
        selectObjectError:
            "Выбор объектов на BIM-модели недоступен в оффлайне. Чтобы выбрать объект подключитесь к Интернету.",
        selectModel: "Выберите модель",
        error: {
            $ref: "не найдена схема {{ ref }}",
            additionalItems_0: "Должно иметь не более, чем {{ count }} элемент",
            additionalItems_1:
                "Должно иметь не более, чем {{ count }} элемента",
            additionalItems_2:
                "Должно иметь не более, чем {{ count }} элементов",
            additionalProperties: "Не должно иметь дополнительные поля",
            anyOf: 'Должно соответствовать одной их схем в "anyOf"',
            const: "Должно быть равно заданному значению",
            contains: "Должно содержать значение соответствующее схеме",
            custom: "Должно соответствовать правилу",
            dependencies_0:
                "{{depsCount}} должно иметь поле {{deeps}}, когда присутствует поле {{property}}",
            dependencies_1:
                "{{depsCount}} должно иметь поля {{deeps}}, когда присутствует поле {{property}}",
            enum: "Должно быть равен одному из значений из списка",
            exclusiveMaximum: "Должно быть {{comparison}} {{count}}",
            exclusiveMinimum: "Должно быть {{comparison}} {{count}}",
            "false schema": "Схема равна false",
            format: "Должно соответствовать формату {{format}}",
            formatExclusiveMaximum:
                "formatExclusiveMaximum должно быть boolean",
            formatExclusiveMinimum:
                "formatExclusiveMinimum должно быть boolean",
            formatMaximum: "Должно быть {{comparison}} {{count}}",
            formatMinimum: "Должно быть {{comparison}} {{count}}",
            if: "Должно соответствовать схемe {{failingKeyword}}",
            maximum: "Должно быть {{comparison}} {{count}}",
            maxItems: "Должно иметь не более, чем {{count}} элемент",
            maxLength_0: "Должно быть не длиннее, чем {{count}} символ",
            maxLength_1: "Должно быть не длиннее, чем {{count}} символа",
            maxLength_2: "Должно быть не длиннее, чем {{count}} символов",
            maxProperties_0: "Должно иметь не более, чем {{count}} поле",
            maxProperties_1: "Должно иметь не более, чем {{count}} поля",
            maxProperties_2: "Должно иметь не более, чем {{count}} полей",
            minimum: "Должно быть {{comparison}} {{count}}",
            minItems: "Должно иметь не менее, чем {{count}} элемент",
            minLength_0: "Должно быть не короче, чем {{count}} символ",
            minLength_1: "Должно быть не короче, чем {{count}} символа",
            minLength_2: "Должно быть не короче, чем {{count}} символов",
            minProperties_0: "Должно иметь не менее, чем {{count}} поле",
            minProperties_1: "Должно иметь не менее, чем {{count}} поля",
            minProperties_2: "Должно иметь не менее, чем {{count}} полей",
            multipleOf: "Должно быть кратным {{multipleOf}}",
            not: 'Должно не соответствовать схеме в "not"',
            oneOf: 'Должно соответствовать в точности одной схемe в "oneOf"',
            pattern: 'Должно соответствовать образцу "{{pattern}}"',
            patternRequired:
                "Должно иметь поле, соответствующее образцу \"{{ missingPattern }}'",
            propertyNames: 'Имя поля "{{propertyName}}" не соответствует схеме',
            required: "Поле обязательно для заполнения",
            switch: 'Должно соответствовать правилу "{{caseIndex}}" в "switch"',
            type: "Должно быть {{ type }}",
            uniqueItems:
                "Не должно иметь повторяющихся элементов (элементы {{j}} и {{i}} идентичны)",
            emailWrongFormat: "Ошибка формата email",
        },
        checkErrors: "Проверьте корректность заполнения полей",
    },
    drawing2d: {
        title: "Отметка на чертеже",
        action: "Отметить на чертеже",
        selectDrawing: "Выберите чертеж",
        actions: {
            move: "Перемещение",
            select: "Выбор",
            delete: "Удаление",
            figures: "Фигуры",
            image: "Отметка",
            arrow: "Стрелка",
            line: "Линия",
            box: "Квадрат",
            circle: "Круг",
            cross: "Крестик",
            pen: "Карандаш",
            polygon: "Произвольная фигура",
            text: "Текст",
        },
    },
    reportDialog: {
        title: "Выберите тип документа",
        titleForm: "Заполните форму",
    },
    menu: {
        title: "Платформа",
        userPlaceholder: "Имя не указано",
    },
    feed: {
        title: "Лента",
        viewFeed: "Посмотреть ленту",
        emptyFeed:
            "Здесь будут отображаться комментарии оставленные вами и другими пользователями.",
        textarea: {
            default: "Введите сообщение",
        },
        loader: "Загрузка сообщений",
        addFiles: "Добавить файлы",
        attachFiles: "Прикрепить файлы",
        attachingFiles: "Прикрепление файлов",
        editing: "Редактирование",
        cancelEditing: "Отменить редактирование",
        confirmDelete: "Удалить сообщение? Данное действие нельзя отменить.",
    },
    view: {
        emptyListMessage: "Здесь пока ничего нет",
        deleteError: "Не удалось удалить элемент",
    },
    delegation: {
        delegate: "Делегировать",
        cancelDelegate: "Отменить делегирование",
        accept: "Принять",
        text: "Делегировано",
    },
    signatureField: {
        showSignText: "Показать подписываемый текст",
        details: "Детали",
        textToSignDialogTitle: "Текст для подписи",
        certificateDialog: {
            cancelSign: "Отозвать подпись",
            issuerName: "Кто выдал",
            subjectName: "Кому выдал",
            valid: "Действителен",
            downloadSignature: "Скачать подпись",
            signVerified: "Подпись верифицирована",
            signNotVerified: "Подпись не верифицирована",
        },
        signDialog: {
            openInstruction: "Открыть инструкцию",
            title: "Электронная подпись",
            errorPassword: "Неверный пароль",
            extensionNotInstalled:
                "Необходимо установить расширение для вашего браузера. Нажмите на кнопку, чтобы открыть инструкцию по установке.",
            emptyDevices: "Нет подключенных устройств",
            emptyCertificates: "Нет сертификатов",
        },
    },
    viewField: {
        selectDialog: {
            title_0: "Выберите не более {{count}} элемента",
            title_1: "Выберите не более {{count}} элементов",
            title_2: "Выберите не более {{count}} элементов",
        },
    },
    dictionary: {
        dialog: {
            title: "Запись справочника",
        },
        getFieldsError: "Не удалось получить данные справочников",
        getItemError: "Не удалось получить запись справочника",
        deleteError: "Не удалось удалить запись справочника",
        updateError: "Не удалось обновить запись справочника",
        createError: "Не удалось создать запись справочника",
    },
};
