import { IEventParser } from "./IEventParser";
import { ISocketEvent } from "../dto/ISocketEvent";
import { IChange, IEventDTO } from "../dto/IEventDTO";
import { EventType } from "@mrs/webclient-shared-ui-lib";

export class BaseEventParser implements IEventParser {
    parseEvent(event: IEventDTO): ISocketEvent {
        return {
            id: event.id,
            eventType: event.eventType,
            context: event.objectType,
            data: this.getObjectChangesFromEvent(event),
        } as ISocketEvent;
    }

    protected isUpdateEvent(eventType: EventType): boolean {
        return eventType === EventType.UPDATE;
    }

    private getObjectChangesFromEvent(event: IEventDTO): object {
        const object: any = {};
        object.id = event.objectId;
        object.type = event.objectType;
        object.collection = event.collection;
        object.classId = event.classId;
        object.classType = event.classType;
        object.initiator = event.initiator;

        if (event.eventType === EventType.CREATE) {
            object.createdDate = event.createDate;
            object.owner = event.initiator;
        }

        const changes = this.collectChanges(event.changes);
        return Object.assign(object, changes);
    }

    private collectChanges(changes: IChange[]) {
        const object: any = {};
        if (changes && changes.length) {
            changes.forEach((change) => {
                try {
                    object[change.field] = JSON.parse(change.value);
                } catch (e) {
                    object[change.field] = change.value;
                }
            });
        }

        return object;
    }
}
