import { DictionaryCustomComponentMapper } from "@ui/components/dictionary/DictionaryItemDialog/mapper/DictionaryCustomComponentMapper";
import { TextFieldComponent } from "@ui/components/shared/CustomComponent/components/TextFieldComponent";
import { CheckboxComponent } from "@ui/components/shared/CustomComponent/components/CheckboxComponent";
import { JsonComponent } from "@ui/components/shared/CustomComponent/components/JsonComponent";
import { TextAreaFieldComponentMapper } from "@ui/components/dictionary/DictionaryItemDialog/mapper/TextAreaComponentMapper";
import { CustomComponentFieldType } from "@ui/components/shared/CustomComponent/interfaces/CustomComponentFieldType";
import { ICustomComponentData } from "@ui/components/shared/CustomComponent/interfaces/ICustomComponentData";
import { DateComponent } from "@ui/components/shared/CustomComponent/components/DateComponent";
import {
    DictionaryFieldType,
    IDictionaryField,
} from "@mrs/webclient-shared-ui-lib";

export type TDictionaryCustomComponentData = Record<
    DictionaryFieldType,
    ICustomComponentData<IDictionaryField>
>;

export const DictionaryCustomComponentData: TDictionaryCustomComponentData = {
    [DictionaryFieldType.STRING]: {
        type: CustomComponentFieldType.String,
        mapper: new DictionaryCustomComponentMapper(),
        component: TextFieldComponent,
    },
    [DictionaryFieldType.TEXT]: {
        type: CustomComponentFieldType.String,
        mapper: new TextAreaFieldComponentMapper(),
        component: TextFieldComponent,
    },
    [DictionaryFieldType.BOOLEAN]: {
        mapper: new DictionaryCustomComponentMapper(),
        component: CheckboxComponent,
    },
    [DictionaryFieldType.JSON]: {
        mapper: new DictionaryCustomComponentMapper(),
        component: JsonComponent,
    },
    [DictionaryFieldType.JSONB]: {
        mapper: new DictionaryCustomComponentMapper(),
        component: JsonComponent,
    },
    [DictionaryFieldType.UUID]: {
        mapper: new DictionaryCustomComponentMapper(),
        type: CustomComponentFieldType.String,
        component: TextFieldComponent,
    },
    [DictionaryFieldType.DECIMAL]: {
        mapper: new DictionaryCustomComponentMapper(),
        type: CustomComponentFieldType.Number,
        component: TextFieldComponent,
    },
    [DictionaryFieldType.INT]: {
        mapper: new DictionaryCustomComponentMapper(),
        type: CustomComponentFieldType.Number,
        component: TextFieldComponent,
    },
    [DictionaryFieldType.FLOAT]: {
        mapper: new DictionaryCustomComponentMapper(),
        type: CustomComponentFieldType.Number,
        component: TextFieldComponent,
    },
    [DictionaryFieldType.DATETIME]: {
        mapper: new DictionaryCustomComponentMapper(),
        component: DateComponent,
    },
    [DictionaryFieldType.DATETIME_WITH_TIME_ZONE]: {
        mapper: new DictionaryCustomComponentMapper(),
        component: DateComponent,
    },
};
