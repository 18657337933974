import React, { useCallback, useState } from "react";
import { ICustomComponent } from "@ui/components/shared/CustomComponent/interfaces/ICustomComponent";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";

interface ICheckboxComponentProps extends ICustomComponent {}

export const CheckboxComponent = (props: ICheckboxComponentProps) => {
    const {
        id,
        value,
        required,
        disabled,
        label,
        errors,
        helperText,
        name,
        hidden,
        readonly,
    } = props;

    const [checked, setChecked] = useState<boolean>(value || false);

    const onChange = useCallback(
        async (
            event: React.ChangeEvent<HTMLInputElement>,
            isChecked: boolean,
        ) => {
            setChecked(isChecked);
            props.onChange && props.onChange(id, isChecked);
        },
        [id, checked],
    );

    return !hidden ? (
        <>
            <FormLabel required={required} htmlFor={id}>
                {label}
            </FormLabel>
            <FormGroup>
                <FormControlLabel
                    key={`control-${name}`}
                    control={
                        <Checkbox
                            readOnly={readonly}
                            checked={checked}
                            onChange={onChange}
                        />
                    }
                    label={"True"}
                    value={checked}
                    disabled={disabled}
                />
            </FormGroup>
            {helperText && (
                <FormHelperText error={!!errors?.length}>
                    {helperText}
                </FormHelperText>
            )}
        </>
    ) : null;
};
