import { IRestService } from "../../../infrastructure/http/IRestService";
import { ContainerModule } from "inversify";
import { DiContainer } from "../../AppDiContainer";
import { DictionaryDiType } from "./diType";
import { IDictionaryItemDialogPresenter } from "@ui/components/dictionary/DictionaryItemDialog/presenter/IDictionaryItemDialogPresenter";
import { DictionaryItemDialogPresenter } from "@ui/components/dictionary/DictionaryItemDialog/presenter/DictionaryItemDialogPresenter";
import { DictionaryEvents } from "./DictionaryEvents";
import { EventsBroker } from "../events/EventsBroker";
import { ObjectType } from "../../ObjectType";
import { DictionaryApiService } from "./apiService/impl/DictionaryApiService";
import { DictionaryRepository } from "./repository/impl/DictionaryRepository";
import { DictionaryService } from "./service/impl/DictionaryService";
import { IDictionaryService } from "./service/IDictionaryService";
import { EventType } from "@mrs/webclient-shared-ui-lib";

export class DictionaryContext {
    start(restService: IRestService): void {
        const dictionaryModule = new ContainerModule((bind) => {
            const resourceName = "dictionary";
            const apiService = new DictionaryApiService(
                restService,
                resourceName,
            );
            const repository = new DictionaryRepository(apiService);
            const service = new DictionaryService(repository);

            bind<IDictionaryService>(
                DictionaryDiType.IDictionaryService,
            ).toConstantValue(service);

            bind<IDictionaryItemDialogPresenter>(
                DictionaryDiType.DictionaryItemDialogPresenter,
            )
                .to(DictionaryItemDialogPresenter)
                .inTransientScope();
        });

        DiContainer.load(dictionaryModule);

        const eventNamesMap: Map<EventType, string> = new Map();
        eventNamesMap.set(EventType.CREATE, DictionaryEvents.onCreatedItem);
        eventNamesMap.set(EventType.UPDATE, DictionaryEvents.onUpdatedItem);
        eventNamesMap.set(EventType.DELETE, DictionaryEvents.onRemoveItem);
        EventsBroker.setEventNames(ObjectType.DICTIONARY, eventNamesMap);
    }
}
