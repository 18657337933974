import {
    IQuery,
    IQueryItem,
    IViewCreationItem,
    IViewFilter,
    IViewFilterParameter,
    IViewForCreate,
    IViewSettings,
    Nullable,
    ViewType,
} from "@mrs/webclient-shared-ui-lib";
import { dto } from "@mrs/platform-configuration-webclient";
import { Identifiable } from "../../../sharedKernel/model/Identifiable";

export class View extends Identifiable {
    private readonly _title: string;
    private readonly _viewType: ViewType;
    private readonly _queries: IQueryItem[];
    private readonly _settings: Nullable<IViewSettings>;
    private readonly _forCreate: Nullable<IViewForCreate>;
    private readonly _creationList: Nullable<IViewCreationItem[]>;
    private readonly _filters: IViewFilter[];
    private readonly _autoSort: boolean;

    constructor(dto: dto.view.ViewDTO) {
        super(dto);
        this._title = dto.title || "";
        this._viewType = dto.viewType.code as ViewType;
        this._queries = dto.queries.map(this.toQueryItem) || [];
        this._settings = (dto.settings as unknown) as Nullable<IViewSettings>;
        this._forCreate = dto.forCreate as Nullable<IViewForCreate>;
        this._creationList = dto.creationList as Nullable<IViewCreationItem[]>;
        this._filters = dto.filters.map(this.toFilter) || [];
        this._autoSort = dto.autoSort;
    }

    get title(): string {
        return this._title;
    }

    get viewType(): ViewType {
        return this._viewType;
    }

    get queries(): IQueryItem[] {
        return this._queries;
    }

    get settings(): Nullable<IViewSettings> {
        return this._settings;
    }

    get forCreate(): Nullable<IViewForCreate> {
        return this._forCreate;
    }

    get creationList(): Nullable<IViewCreationItem[]> {
        return this._creationList;
    }

    get filters(): IViewFilter[] {
        return this._filters;
    }

    get autoSort(): boolean {
        return this._autoSort;
    }

    private toFilter = (el: dto.view.filters.Filter): IViewFilter => {
        return {
            title: el.title,
            placeholder: el.placeholder,
            viewId: (el as dto.view.filters.ViewFilter).viewId,
            fields: (el as dto.view.filters.ViewFilter).fields,
            type: el.filterType.code,
            icon: el.icon.code,
            multiple: el.multiple,
            inSidebar: el.inSidebar,
            params: (el as dto.view.filters.ViewFilter)
                .params as IViewFilterParameter[],
        } as IViewFilter;
    };

    private toQueryItem = (item: dto.view.QueryItem): IQueryItem => {
        return {
            name: item.name,
            query: !!item.raw ? this.toQuery(item.raw) : null,
            listeners: item.listeners as Nullable<IViewForCreate[]>,
            hook: !!item.map
                ? () => new Function(`return (${item.map})`)()
                : undefined,
        };
    };

    private toQuery = (dtoQuery: string): IQuery => {
        const queryData = dtoQuery?.replace(/{{.+?}}/g, "");
        return (!!queryData ? JSON.parse(queryData) : {}) as IQuery;
    };
}
