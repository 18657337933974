import { injectable } from "inversify";
import { IDictionaryItemDialogPresenter } from "@ui/components/dictionary/DictionaryItemDialog/presenter/IDictionaryItemDialogPresenter";
import { IViewItemDialogParams } from "@ui/components/view/uiInterfaces/IViewItemDialogPresenter";
import { createRoot } from "react-dom/client";
import {
    DictionaryFieldType,
    DictionaryUtils,
    IDictionaryField,
    IDictionaryItemDto,
    IDOMElement,
    IdUtils,
    Nullable,
    ObjectUtils,
} from "@mrs/webclient-shared-ui-lib";
import { action, computed, observable, toJS } from "mobx";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { BaseTheme } from "@ui/theme/baseTheme/BaseTheme";
import React from "react";
import { DictionaryItemDialog } from "@ui/components/dictionary/DictionaryItemDialog/DictionaryItemDialog";
import { DictionaryCustomComponentData } from "@ui/components/dictionary/DictionaryItemDialog/DictionaryCustomComponentData";
import { lazyInject } from "../../../../../core/AppDiContainer";
import { DictionaryDiType } from "../../../../../core/context/dictionary/diType";
import { IDictionaryService } from "../../../../../core/context/dictionary/service/IDictionaryService";
import { ICustomComponentData } from "@ui/components/shared/CustomComponent/interfaces/ICustomComponentData";
import { ICustomComponentViewItems } from "@ui/components/shared/CustomComponent/interfaces/ICustomComponentViewItems";
import cloneDeep from "lodash-es/cloneDeep";

@injectable()
export class DictionaryItemDialogPresenter
    implements IDictionaryItemDialogPresenter {
    @observable private _isOpen: boolean = false;
    @observable private _isLoading: boolean = true;
    @observable private _hasError: boolean = false;
    @observable private _fields: ICustomComponentViewItems[] = [];
    @observable private _canApply: boolean = false;
    private _currentPrimaryKey: Nullable<string> = null;
    private _onClose?: () => void;
    private _isCreation?: boolean = true;
    private _baseItem: Nullable<IDictionaryItemDto> = null;
    private _primaryKeyNames: Set<string> = new Set();
    private _requiredFieldNames: Set<string> = new Set();
    @observable private _currentItem: Nullable<IDictionaryItemDto> = null;
    private popupElement: IDOMElement = {
        element: document.createElement("div"),
    };

    @lazyInject(DictionaryDiType.IDictionaryService)
    private readonly service: IDictionaryService;

    @computed
    public get fields(): ICustomComponentViewItems[] {
        return toJS(this._fields);
    }

    @computed
    public get currentItem(): Nullable<IDictionaryItemDto> {
        return toJS(this._currentItem);
    }

    @computed
    public get isOpen(): boolean {
        return toJS(this._isOpen);
    }

    @computed
    public get isLoading(): boolean {
        return toJS(this._isLoading);
    }

    @computed
    public get hasError(): boolean {
        return toJS(this._hasError);
    }

    @computed
    public get canApply(): boolean {
        return toJS(this._canApply);
    }

    onClose(): void {
        if (!document.body.contains(this.popupElement.element)) return;
        this.popupElement.root?.unmount();
        this.popupElement.element.remove();
        this._isOpen = false;
        this._hasError = false;
        this._fields = [];
        this._baseItem = null;
        this._currentItem = null;
        this._primaryKeyNames.clear();
        this._onClose && this._onClose();
    }

    async showModal(params: IViewItemDialogParams): Promise<void> {
        this.popupElement.root = createRoot(this.popupElement.element);
        document.body.appendChild(this.popupElement.element);
        this._isLoading = true;
        this._isOpen = true;
        this._onClose = params.onClose;
        this._isCreation = !params.id;
        this._currentPrimaryKey = params.id;
        this.initItems(params.id);
        this.renderModal();
    }

    async onApply(): Promise<void> {
        this._isCreation
            ? this.createDictionaryItem()
            : this.updateDictionaryItem();
        this.onClose();
    }

    private renderModal = () => {
        this.popupElement.root?.render(
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={BaseTheme}>
                    <DictionaryItemDialog presenter={this} />
                </ThemeProvider>
            </StyledEngineProvider>,
        );
    };

    private async initItems(itemKey?: string): Promise<void> {
        try {
            const parsedItemKey = this.parseItemKey(itemKey);
            const fields = await this.getCurrentFields();
            this.initFieldsNames(fields);
            this._currentItem = parsedItemKey
                ? await this.getCurrentItem(parsedItemKey)
                : this.createBaseItem(fields);
            this._baseItem = cloneDeep(this.currentItem);
            this._fields = this.toCustomElements(fields);
            this.checkCanApply();
        } catch (e) {
            this._hasError = true;
            console.error(
                "Error when getting dictionary item with key ",
                itemKey && decodeURIComponent(itemKey),
            );
        } finally {
            this._isLoading = false;
        }
    }

    @action
    private setFieldValue = (name: string, value: any) => {
        this._currentItem && (this._currentItem[name] = value);
        this.checkCanApply();
    };

    private createDictionaryItem() {
        this.currentItem && this.service.createDictionaryItem(this.currentItem);
    }

    private updateDictionaryItem() {
        if (this.currentItem && this._baseItem && this._currentPrimaryKey) {
            const changes = ObjectUtils.findChanges(
                this.currentItem,
                this._baseItem,
                false,
            ) as IDictionaryItemDto;
            changes &&
                this.service.updateDictionaryItem(
                    this._currentPrimaryKey,
                    changes,
                );
        }
    }

    private toCustomElements(
        items: IDictionaryField[],
    ): ICustomComponentViewItems[] {
        return items.map((item: IDictionaryField) => {
            const {
                type,
                mapper,
                component,
            } = this.getCustomComponentDataByType(item.type);
            const itemProps =
                mapper &&
                mapper.toCustomComponentProps(item, {
                    onChange: this.setFieldValue,
                    onBlur: this.setFieldValue,
                    type,
                });
            return { itemProps, component };
        });
    }

    private getCustomComponentDataByType(
        type: DictionaryFieldType,
    ): ICustomComponentData {
        return DictionaryCustomComponentData[type];
    }

    private async getCurrentFields(): Promise<IDictionaryField[]> {
        return this.service.getDictionaryFields();
    }

    private async getCurrentItem(
        itemKey: object,
    ): Promise<IDictionaryItemDto | null> {
        return this.service.getDictionaryItem(itemKey);
    }

    private createBaseItem(fields: IDictionaryField[]): IDictionaryItemDto {
        const baseItem: any = {};
        fields.forEach((field) => (baseItem[field.field_name] = undefined));
        for (const primaryKeyName of this._primaryKeyNames.keys()) {
            baseItem[primaryKeyName] = IdUtils.createUUID();
        }
        return baseItem;
    }

    private initFieldsNames(fields: IDictionaryField[]): void {
        for (const field of fields) {
            if (DictionaryUtils.isReadonly(field.type, field.parameters)) {
                this._primaryKeyNames.add(field.field_name);
            }
            if (DictionaryUtils.isRequired(field.parameters)) {
                this._requiredFieldNames.add(field.field_name);
            }
        }
    }

    @action
    private checkCanApply = (): void => {
        this._canApply = Array.from(this._requiredFieldNames).every(
            (requiredFieldName) =>
                this._currentItem && !!this._currentItem[requiredFieldName],
        );
    };

    private parseItemKey = (itemKey?: string) => {
        try {
            return itemKey ? JSON.parse(decodeURIComponent(itemKey)) : null;
        } catch (e) {
            console.log(e);
            return null;
        }
    };
}
