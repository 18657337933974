import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
    useMemo,
} from "react";
import { ViewFilter } from "../ViewFilter/ViewFilter";
import { motion } from "framer-motion";
import { PanInfo } from "framer-motion/types/gestures/PanSession";
import {
    FluentUiIconName,
    Icon,
    TVoidCallback,
    ICheckedFilter,
} from "@mrs/webclient-shared-ui-lib";
import { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
    DIVIDER_HEIGHT,
    HEADER_HEIGHT,
    MENU_WIDTH,
} from "@ui/theme/baseTheme/BaseTheme";
import { appStorage } from "../../../../../infrastructure/storage/AppStorage";
import set from "lodash-es/set";

const LS_SIDEBAR_WIDTH = "sidebar-width";
const SIDEBAR_WIDTH = 320;
const MAX_SIDEBAR_WIDTH = 1000;

const getSidebarSizes = () => {
    const result = appStorage.getItem(LS_SIDEBAR_WIDTH);
    return result ? JSON.parse(result) : {};
};
const getWidthByViewName = (viewName: string): number => {
    const sizes = getSidebarSizes();
    return sizes[viewName] || SIDEBAR_WIDTH;
};

interface IViewFilterSidebarProps {
    viewName: string;
    filter: ICheckedFilter;
    parentName: string;
    disabled?: boolean;
    autoFocus?: boolean;
    withBackIcon?: boolean;
    menu?: React.JSX.Element;
    onChange: TVoidCallback<ICheckedFilter>;
    onBack?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

export const ViewFilterSidebar = (props: IViewFilterSidebarProps) => {
    const { viewName, filter, disabled, onChange, onBack } = props;
    const contentRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(getWidthByViewName(viewName));

    useEffect(() => setWidth(getWidthByViewName(viewName)), [viewName]);

    const handleDrag = React.useCallback(
        (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
            const newWidth: number = info.point.x - MENU_WIDTH;
            if (newWidth >= SIDEBAR_WIDTH && newWidth < MAX_SIDEBAR_WIDTH) {
                setWidth(newWidth);
                const sizes = getSidebarSizes();
                set(sizes || {}, viewName, newWidth);
                appStorage.setItem(LS_SIDEBAR_WIDTH, JSON.stringify(sizes));
            }
        },
        [],
    );

    return (
        <>
            <Box
                sx={{
                    width,
                    minWidth: width,
                    cursor: isDragging ? "col-resize" : "",
                    ...styles.sidebar,
                }}
            >
                <Box sx={styles.header}>
                    {props.menu}
                    {!!props.withBackIcon && (
                        <IconButton sx={{ mr: 2, p: 0 }} onClick={onBack}>
                            <Icon
                                iconName={FluentUiIconName.ArrowLeftRegular}
                            />
                        </IconButton>
                    )}
                    <Typography variant={"subtitle2"} color={"textPrimary"}>
                        {filter.value.title}
                    </Typography>
                </Box>
                <Divider />
                <ViewFilter
                    currentFilter={filter}
                    parentName={props.parentName}
                    autoFocus={props.autoFocus}
                    disabled={disabled}
                    contentRef={contentRef}
                    containerRef={contentRef}
                    onChange={onChange}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    sx={styles.filterContainer}
                />
            </Box>
            <Box
                sx={[
                    styles.divider,
                    styles.motionContainer,
                    ...(isDragging ? [styles.isDragging] : []),
                ]}
            >
                <motion.div
                    drag={"x"}
                    dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    dragElastic={0}
                    dragMomentum={false}
                    dragPropagation={true}
                    onDrag={handleDrag}
                    onDragEnd={() => {
                        setIsDragging(false);
                    }}
                    onDragStart={() => {
                        setIsDragging(true);
                    }}
                />
            </Box>
        </>
    );
};

const styles = {
    sidebar: {
        overflow: "hidden",
        backgroundColor: "background.paper",
    },
    divider: {
        cursor: "col-resize",
        backgroundColor: "divider",
    },
    isDragging: {
        borderLeft: (theme: Theme) =>
            `${theme.spacing(1)} solid ${theme.palette.divider}`,
        "& > div:hover": {
            borderLeftStyle: "unset",
            borderLeftColor: "unset",
        },
    },
    motionContainer: {
        "& > div": {
            height: "100%",
            cursor: "col-resize",
            width: 9,
            boxSizing: "border-box",
            backgroundClip: "padding-box",
            ml: -1,
            borderLeftWidth: (theme: Theme) => theme.spacing(1),
            "&:hover": {
                transition: "margin-right 2s ease",
                "-webkit-transition": "margin-right 2s ease",
                borderLeftStyle: "solid",
                borderLeftColor: "divider",
            },
        },
    },
    header: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        p: (theme: Theme) => theme.spacing(0, 2),
        boxSizing: "border-box",
        minHeight: HEADER_HEIGHT,
    },
    filterContainer: {
        height: `calc(100% - ${HEADER_HEIGHT + DIVIDER_HEIGHT}px)`,
        overflow: "auto",
        "&::-webkit-scrollbar": {
            width: 0,
            height: 8,
        },
        "&::-webkit-scrollbar-thumb:horizontal": {
            backgroundColor: "#9E9E9E",
            borderRadius: 1,
        },
    },
};
