import { observer } from "mobx-react";
import React, { useCallback, useMemo } from "react";
import { IDictionaryItemDialogPresenter } from "./presenter/IDictionaryItemDialogPresenter";
import {
    EmptyList,
    IActionButtonProps,
    UniversalDialog,
} from "@mrs/webclient-shared-ui-lib";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { DEFAULT_NAMESPACE } from "@lib/translator/i18next";
import CircularProgress from "@mui/material/CircularProgress";

interface IDictionaryDialogProps {
    presenter: IDictionaryItemDialogPresenter;
}

export const DictionaryItemDialog = observer(
    (props: IDictionaryDialogProps) => {
        const {
            isOpen,
            isLoading,
            hasError,
            currentItem,
            fields,
            canApply,
        } = props.presenter;
        const { t } = useTranslation(DEFAULT_NAMESPACE);

        const onClickClose = useCallback(() => props.presenter.onClose(), []);
        const onClickApply = useCallback(() => props.presenter.onApply(), []);

        const actions: IActionButtonProps[] = useMemo(() => {
            return [
                {
                    title: t("common.cancel"),
                    onClick: onClickClose,
                    buttonVariant: "text",
                    color: "secondary",
                    fullWidth: false,
                    size: "medium",
                },
                {
                    title: t("common.apply"),
                    onClick: onClickApply,
                    buttonVariant: "text",
                    color: "primary",
                    fullWidth: false,
                    disabled: !canApply,
                    size: "medium",
                    sx: {
                        color: "primary.main",
                    },
                },
            ];
        }, [canApply]);

        return (
            <UniversalDialog
                isOpen={isOpen}
                title={t("dictionary.dialog.title")}
                onClose={onClickClose}
                actions={actions}
                dialogProps={{
                    sx: {
                        "& .MuiDialog-paper": {
                            height: "100%",
                            width: "100%",
                        },
                    },
                }}
            >
                <>
                    {isLoading ? (
                        <Box
                            sx={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress size={36} />
                        </Box>
                    ) : hasError ? (
                        <EmptyList
                            message={t("common:dictionary.getItemError")}
                        />
                    ) : (
                        <Box p={2}>
                            {fields.map(({ itemProps, component }) => (
                                <Box
                                    key={itemProps.id}
                                    mb={itemProps.hidden ? 0 : 3}
                                >
                                    {React.createElement(component, {
                                        ...itemProps,
                                        value: currentItem
                                            ? currentItem[itemProps.id]
                                            : "",
                                    })}
                                </Box>
                            ))}
                        </Box>
                    )}
                </>
            </UniversalDialog>
        );
    },
);
