import { IDictionaryCustomComponentMapper } from "@ui/components/dictionary/DictionaryItemDialog/mapper/IDictionaryCustomComponentMapper";
import {
    ICustomComponent,
    ICustomComponentBaseProps,
} from "@ui/components/shared/CustomComponent/interfaces/ICustomComponent";
import {
    DictionaryUtils,
    IDictionaryField,
} from "@mrs/webclient-shared-ui-lib";

export class DictionaryCustomComponentMapper
    implements IDictionaryCustomComponentMapper {
    toCustomComponentProps(
        item: IDictionaryField,
        baseProps?: ICustomComponentBaseProps<IDictionaryField>,
    ): ICustomComponent {
        return {
            id: item.field_name,
            label: item.field_human_name || item.field_name,
            name: item.field_name,
            onChange: baseProps?.onChange,
            onBlur: baseProps?.onBlur,
            helperText: "",
            type: baseProps?.type,
            value: baseProps?.value || "",
            readonly: DictionaryUtils.isReadonly(item.type, item.parameters),
            required: DictionaryUtils.isRequired(item.parameters),
        };
    }
}
