import { ObjectType } from "../../../../core/ObjectType";
import { FormInstanceDiType } from "../../../../core/context/formInstance/diType";
import { DictionaryDiType } from "../../../../core/context/dictionary/diType";

export const itemDialogPresenterByType: Record<string, string> = {
    [ObjectType.FORM_INSTANCE]: FormInstanceDiType.FormInstanceDialogPresenter,
    [ObjectType.DICTIONARY]: DictionaryDiType.DictionaryItemDialogPresenter,
};

export const addDialogPresenterByType: Record<string, string> = {
    [ObjectType.FORM_INSTANCE]:
        FormInstanceDiType.AddFormInstanceDialogPresenter,
    [ObjectType.DICTIONARY]: DictionaryDiType.DictionaryItemDialogPresenter,
};
