import React, { useCallback, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { ITextFieldComponent } from "@ui/components/shared/CustomComponent/interfaces/ITextFieldComponent";
import { CustomComponentFieldType } from "@ui/components/shared/CustomComponent/interfaces/CustomComponentFieldType";

export interface ITextFieldComponentProps extends ITextFieldComponent {}

export const TextFieldComponent = (props: ITextFieldComponentProps) => {
    const {
        id,
        required,
        disabled,
        label,
        autofocus,
        errors = [],
        helperText,
        rowsMin,
        rowsMax,
        multiline,
        readonly,
        hidden,
    } = props;

    const [value, setValue] = React.useState<string>(props.value);

    useEffect(() => {
        if (props.value !== value) setValue(props.value);
    }, [props.value]);

    const onChange = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            setValue(target.value || "");
        },
        [],
    );

    const onBlur = useCallback(
        async ({ target }: React.FocusEvent<HTMLInputElement>) => {
            if (!props.onBlur) return;
            try {
                const parsedValue =
                    props.type === CustomComponentFieldType.Number
                        ? Number(target.value)
                        : target.value;
                props.onBlur(id, parsedValue);
            } catch (e) {
                console.log(e);
            }
        },
        [id],
    );

    return !hidden ? (
        <TextField
            id={id}
            label={label}
            autoFocus={autofocus}
            required={required}
            disabled={disabled}
            type={props.type}
            value={value || ""}
            error={!!errors.length}
            onChange={onChange}
            onBlur={onBlur}
            helperText={helperText}
            fullWidth
            InputProps={{ notched: false, readOnly: readonly }}
            InputLabelProps={{ shrink: true }}
            placeholder={props.placeholder}
            variant={"outlined"}
            minRows={rowsMin}
            maxRows={rowsMax}
            multiline={multiline}
        />
    ) : null;
};
