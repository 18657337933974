import { DictionaryCustomComponentMapper } from "@ui/components/dictionary/DictionaryItemDialog/mapper/DictionaryCustomComponentMapper";
import { ITextFieldComponent } from "@ui/components/shared/CustomComponent/interfaces/ITextFieldComponent";
import { ICustomComponentBaseProps } from "@ui/components/shared/CustomComponent/interfaces/ICustomComponent";
import { IDictionaryField } from "@mrs/webclient-shared-ui-lib";

export class TextAreaFieldComponentMapper extends DictionaryCustomComponentMapper {
    toCustomComponentProps(
        item: IDictionaryField,
        baseProps?: ICustomComponentBaseProps<IDictionaryField>,
    ): ITextFieldComponent {
        return {
            rowsMax: 5,
            rowsMin: 3,
            multiline: true,
            ...super.toCustomComponentProps(item, baseProps),
        };
    }
}
