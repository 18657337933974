import React from "react";
import { motion } from "framer-motion";
import { Variants } from "framer-motion/types/types";
import { useTranslation } from "react-i18next";
import { IndentSize } from "@ui/theme/baseTheme/BaseTheme";
import { ANIMATION_CONST } from "@ui/theme/baseTheme/animationConst";
import { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { CheckCircleOutline } from "@mui/icons-material";
import { AnimatedComponent } from "@mrs/webclient-shared-ui-lib";

const SAVE_CHANGES_BUTTON_HEIGHT = 48;
const SAVE_CHANGES_BUTTON_MIN_WIDTH = 240;

interface ISaveButtonProps {
    isShow: boolean;
    isLoading: boolean;
    isChangesSaved: boolean;
    onClick?: () => void;
}

enum ANIMATION_TYPE {
    OPENED = "opened",
    CLOSED = "closed",
}

const ANIMATION_VARIANTS: Variants = {
    [ANIMATION_TYPE.OPENED]: {
        y: `calc(100% - (${2 * SAVE_CHANGES_BUTTON_HEIGHT}px + ${
            IndentSize.Small
        }px))`,
        opacity: 1,
    },
    [ANIMATION_TYPE.CLOSED]: {
        y: "100%",
        opacity: 0,
    },
};

export const SaveButton = (props: ISaveButtonProps) => {
    const { isShow, isLoading, isChangesSaved, onClick } = props;

    const { t } = useTranslation();

    return (
        <Box sx={styles.saveChangesButtonContainer}>
            <AnimatedComponent initial={false}>
                {isShow && (
                    <motion.div
                        key={"saveChanges"}
                        variants={ANIMATION_VARIANTS}
                        animate={ANIMATION_TYPE.OPENED}
                        exit={ANIMATION_TYPE.CLOSED}
                        transition={ANIMATION_CONST.transition}
                    >
                        <Button
                            sx={[
                                styles.saveChangesButton,
                                ...(isChangesSaved
                                    ? [styles.isSaveChanges]
                                    : []),
                            ]}
                            variant={"contained"}
                            color={"primary"}
                            onClick={onClick}
                            startIcon={isChangesSaved && <CheckCircleOutline />}
                            data-ui-test={"applyChangeButton"}
                            size={"large"}
                        >
                            {isLoading ? (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: "absolute",
                                        color: "background.paper",
                                    }}
                                />
                            ) : !isChangesSaved ? (
                                t("common:common.saveChanges")
                            ) : (
                                t("common:common.changesSaved")
                            )}
                        </Button>
                    </motion.div>
                )}
            </AnimatedComponent>
        </Box>
    );
};

const styles = {
    saveChangesButtonContainer: {
        "& > div": {
            width: "100%",
            textAlign: "center",
        },
    },
    saveChangesButton: {
        minWidth: SAVE_CHANGES_BUTTON_MIN_WIDTH,
        position: "relative",
        boxShadow: (theme: Theme) => theme.shadows[4],
    },
    isSaveChanges: {
        backgroundColor: "success.main",

        "&:hover": {
            backgroundColor: "success.main",
        },
    },
};
