import { IWithId, ObjectId } from "@mrs/webclient-shared-ui-lib";
import { IWithType } from "./IWithType";
import { ObjectType } from "../../ObjectType";
import { Exclude, Expose } from "class-transformer";

export type TTypedIdentifiable = IWithId & IWithType;

@Exclude()
export abstract class TypedIdentifiable implements TTypedIdentifiable {
    private _id!: ObjectId;

    @Expose()
    get id(): ObjectId {
        return this._id;
    }

    set id(value: ObjectId) {
        this._id = value;
    }

    abstract get type(): ObjectType;
}
