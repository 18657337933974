import { IResource } from "@lib/translator/Translator";

export const en: IResource = {
    common: {
        close: "close",
        cancel: "Cancel",
        delete: "Delete",
        edit: "Edit",
        changed: "Changed",
        apply: "Apply",
        select: "Select",
        open: "Open",
        search: "Search",
        filter: "Filter",
        create: "Create",
        send: "Send",
        enter: "Enter",
        sign: "Sign",
        device: "Device",
        pin: "Pin",
        certificate: "Certificate",
        nothingFound: "No results found",
        noItems: "No items",
        generateDocument: "Generate document",
        download: "Download",
        additionalInfo: "Additional information",
        confirmDelete:
            "Are you sure you want to delete? This action cannot be undone.",
        actions: "Actions",
        selectAll: "Select all",
        selectAction: "Select action",
        save: "Save",
        saveChanges: "Save changes",
        changesSaved: "Changes saved",
        reset: "Reset",
        resetAll: "Reset all",
        filters: "Filters",
        applyFilters: "Apply filters",
        moreActions: "More",
        generate: "Generate",
        owner: "Author",
        organization: "Organization",
        confirmCloseWithChanges:
            "You have changed the data. Are you sure you want to discard your changes?",
        ready: "Ready",
        start: "Start",
        end: "End",
        tabs: {
            main: "Main",
            information: "Information",
        },
        fileSize: {
            mb: "Mb",
        },
        ok: "OK",
        createDate: "Created date",
        creation: "Creation",
        linesPerPage: "Lines per page",
        navigation: {
            hide: "Hide navigation",
            show: "Show navigation",
        },
    },
    pages: {
        settings: "Settings",
    },
    settings: {
        language: {
            title: "Language",
            ru: "Russian",
            en: "English",
        },
        exit: "Exit",
        logoutConfirm: "You must be online to log in. \nLog out?",
    },
    login: {
        subnet: {
            hint:
                "This is a link with your corporate address that you received in the mail.",
            continue: "Continue",
            orgAddress: "URL-address",
            orgEnterTitle: "Enter company code",
        },

        notValid: "Invalid login details",
        subnetPlaceholder: "Enter URL",
        subnetError: "Invalid url to login to the organization",

        tryLoginButton: "Try again",

        loginErrorMessages: {
            176: "Your organization does not have enough licenses. Contact your administrator for log in.",
            500: "An error has occurred on the server. Please contact your administrator.",
        },
    },
    reportTemplateDialog: {
        loadingDocument: "The document is being formed",
        error: "Error when generating document",
        success: "The document has been generated",
    },
    datePicker: {
        error: {
            maxDateError: "Incorrect date entered",
            minDateError: "Incorrect date entered",
            dateFormatError: "Incorrect date entered",
            dateRangeError: "Invalid date range",
        },
    },
    formInstance: {
        create: "Add form instance",
        createButton: "Create",
        enterTitle: "Enter title",
        title: "Title",
        canNotUpdate: "You cannot change the form",
        updateError: "Failed to update form",
        createError: "Failed to create form",
    },
    attachment: {
        fileUpload: "File upload",
        errorMaxAttachmentAmount: "You can upload only {{count}} files",
        errorMaxAttachmentAmount_plural: "You can upload only {{count}} files",
        errorFileType: "Unsupported file type",
        errorFileSize: "File size must be less then {{value}} Mb",
        errorUnknown: "File uploading error",
        uploadError: "Loading error",
        loading: "Loading",
        loadingWithSize: "Loading {{done}} MB from {{total}} Mb",
        uploadButtonMessage: "Maximum size {{value}} Mb",
        emptyList: "No files",
    },
    customForm: {
        selectUser: {
            title: "Select user",
            noOptions: "Нет совпадений",
            userNamePlaceholder: "Введите имя пользователя",
            assignedTo: "Проверяющий",
        },
        selectStatus: {
            placeholder: "Выберите статус",
        },
        selectObject: "Select object on BIM-model",
        selectObjectError:
            "The selection of objects on the BIM-model is not available offline. To select an object, connect to the Internet.",
        selectModel: "Select model",
        error: {
            $ref: "не найдена схема {{ ref }}",
            additionalItems_0: "Должно иметь не более, чем {{ limit }} элемент",
            additionalItems_1:
                "Должно иметь не более, чем {{ limit }} элемента",
            additionalItems_2:
                "Должно иметь не более, чем {{ limit }} элементов",
            additionalProperties: "Не должно иметь дополнительные поля",
            anyOf: 'Должно соответствовать одной их схем в "anyOf"',
            const: "Должно быть равно заданному значению",
            contains: "Должно содержать значение соответствующее схеме",
            custom: "Должно соответствовать правилу",
            dependencies_0:
                "{{depsCount}} должно иметь поле {{deeps}}, когда присутствует поле {{property}}",
            dependencies_1:
                "{{depsCount}} должно иметь поля {{deeps}}, когда присутствует поле {{property}}",
            enum: "Должно быть равен одному из значений из списка",
            exclusiveMaximum: "Должно быть {{comparison}} {{limit}}",
            exclusiveMinimum: "Должно быть {{comparison}} {{limit}}",
            "false schema": "Схема равна false",
            format: "Должно соответствовать формату {{format}}",
            formatExclusiveMaximum:
                "formatExclusiveMaximum должно быть boolean",
            formatExclusiveMinimum:
                "formatExclusiveMinimum должно быть boolean",
            formatMaximum: "Должно быть {{comparison}} {{limit}}",
            formatMinimum: "Должно быть {{comparison}} {{limit}}",
            if: "Должно соответствовать схемe {{failingKeyword}}",
            maximum: "Должно быть {{comparison}} {{limit}}",
            maxItems: "Должно иметь не более, чем {{limit}} элемент",
            maxLength_0: "Должно быть не длиннее, чем {{limit}} символ",
            maxLength_1: "Должно быть не длиннее, чем {{limit}} символа",
            maxLength_2: "Должно быть не длиннее, чем {{limit}} символов",
            maxProperties_0: "Должно иметь не более, чем {{limit}} поле",
            maxProperties_1: "Должно иметь не более, чем {{limit}} поля",
            maxProperties_2: "Должно иметь не более, чем {{limit}} полей",
            minimum: "Должно быть {{comparison}} {{limit}}",
            minItems: "Должно иметь не менее, чем {{limit}} элемент",
            minLength_0: "Должно быть не короче, чем {{limit}} символ",
            minLength_1: "Должно быть не короче, чем {{limit}} символа",
            minLength_2: "Должно быть не короче, чем {{limit}} символов",
            minProperties_0: "Должно иметь не менее, чем {{limit}} поле",
            minProperties_1: "Должно иметь не менее, чем {{limit}} поля",
            minProperties_2: "Должно иметь не менее, чем {{limit}} полей",
            multipleOf: "Должно быть кратным {{multipleOf}}",
            not: 'Должно не соответствовать схеме в "not"',
            oneOf: 'Должно соответствовать в точности одной схемe в "oneOf"',
            pattern: 'Должно соответствовать образцу "{{pattern}}"',
            patternRequired:
                "Должно иметь поле, соответствующее образцу \"{{missingPattern}}'",
            propertyNames: 'Имя поля "{{propertyName}}" не соответствует схеме',
            required: 'Поле "{{missingProperty}}" обязательно для заполнения',
            switch: 'Должно соответствовать правилу "{{caseIndex}}" в "switch"',
            type: "Должно быть {{type}}",
            uniqueItems:
                "Не должно иметь повторяющихся элементов (элементы {{j}} и {{i}} идентичны)",
            emailWrongFormat: "Ошибка формата email",
        },
        checkErrors: "Check if the fields are filled in correctly",
    },
    drawing2d: {
        title: "Mark on the drawing",
        action: "Mark on the drawing",
        selectDrawing: "Select drawing",
        actions: {
            move: "Move",
            select: "Select",
            delete: "Delete",
            figures: "Figures",
            image: "Image",
            arrow: "Arrow",
            line: "Line",
            box: "Box",
            circle: "Circle",
            cross: "Cross",
            pen: "Pen",
            polygon: "Polygon",
            text: "Text",
        },
    },
    reportDialog: {
        title: "Select document type",
        titleForm: "Fill the form",
    },
    menu: {
        title: "Platform",
        userPlaceholder: "Name not specified",
    },
    feed: {
        title: "Feed",
        viewFeed: "View feed",
        emptyFeed:
            "Comments posted by you and other users will be displayed here.",
        textarea: {
            default: "Enter your message",
        },
        loader: "Загрузка сообщений",
        addFiles: "Add files",
        attachFiles: "Attach files",
        attachingFiles: "Attaching files",
        editing: "Editing",
        cancelEditing: "Cancel editing",
        confirmDelete: "Delete message? This action cannot be undone.",
    },
    view: {
        emptyListMessage: "There is nothing here yet",
        deleteError: "Failed to delete item",
    },
    delegation: {
        delegate: "Delegate",
        cancelDelegate: "Cancel Delegation",
        accept: "Accept",
        text: "Delegated",
    },
    signatureField: {
        showSignText: "Show signed text",
        details: "Details",
        textToSignDialogTitle: "Signature text",
        certificateDialog: {
            cancelSign: "Revoke signature",
            issuerName: "Who issued",
            subjectName: "To whom issued",
            valid: "Valid",
            downloadSignature: "Download signature",
            signVerified: "Signature verified",
            signNotVerified: "Signature not verified",
        },
        signDialog: {
            openInstruction: "Open instruction",
            title: "Signature",
            errorPassword: "Incorrect password",
            extensionNotInstalled:
                "You need to install extension for your browser. Click on the button to open the installation instructions.",
            emptyDevices: "No devices connected",
            emptyCertificates: "No certificates",
        },
    },
    viewField: {
        selectDialog: {
            title_0: "Select no more than {{count}} item",
            title_1: "Select no more than {{count}} items",
            title_2: "Select no more than {{count}} items",
        },
    },
    dictionary: {
        dialog: {
            title: "Directory entry",
        },
        getFieldsError: "Failed to obtain directory data",
        getItemError: "Failed to get directory entry",
        deleteError: "Failed to delete directory entry",
        updateError: "Failed to update directory entry",
        createError: "Failed to create directory entry",
    },
};
